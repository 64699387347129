import React from 'react';

export default function SectionHeading(props) {
  return (
  	<div className="pb-4">
	  	<div className="block sm:hidden border-white w-full py-2" style={{ borderTopWidth: "16px" }} />
	    <div className="flex flex-row items-center">
	    	{props.h1 ? 
	    		<><h1 className="mx-auto sm:ml-0 text-center sm:text-left h1 font-headings text-5xl sm:text-6xl text-white pr-4">{props.text}</h1><div className="hidden sm:block grow flex-1 bg-white h-6 mt-10" /></>
	    		: <><h2 className="mx-auto sm:ml-0 text-center sm:text-left h2 font-headings text-4xl sm:text-5xl text-white pr-4">{props.text}</h2><div className="hidden sm:block grow flex-1 bg-white h-4 mt-5" /></>
	    	}
	  	</div>
	  	{props.subheading && <p className="mx-auto sm:ml-0 text-center sm:text-left font-semibold text-xl sm:text-2xl w-full"><span className="text-transparent bg-clip-text bg-opacity-90 bg-white">{props.subheading}</span>{props.subheadingEmoji}</p>}
  	</div>
  )
}
