import * as React from 'react';
import ImageList from '@mui/material/ImageList';

function Drawing(props) {
  return <img src={props.img} alt={props.title} loading="lazy" className="mb-2 overflow-hidden rounded drop-shadow-2xl" />
}

export default function GalleryArt() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto border-white pt-2" style={{ borderTopWidth: "16px" }} data-aos="fade-down" data-aos-delay="400">
        <div className="hidden sm:block">
          <ImageList variant="masonry" cols={4} gap={8}>
            {itemData.map((item) => <Drawing {...item} />)}
          </ImageList>
        </div>
        <div className="block sm:hidden">
          <ImageList variant="masonry" cols={2} gap={8}>
            {itemData.map((item) => <Drawing {...item} />)}
          </ImageList>
        </div>
      </div>
    </section>
  );
}

const itemData = [
  {
    img: require('../assets/sketches/Sketches-1.jpg'),
    title: 'Florence',
  },
  {
    img: require('../assets/sketches/Sketches-2.jpg'),
    title: 'Manarola',
  },
  {
    img: require('../assets/sketches/Sketches-3.jpg'),
    title: 'Manarola',
  },
  {
    img: require('../assets/sketches/Sketches-4.jpg'),
    title: "Manarola's rocks",
  },
  {
    img: require('../assets/sketches/Sketches-5.jpg'),
    title: 'Portofino',
  },
  {
    img: require('../assets/sketches/Sketches-6.jpg'),
    title: 'Portofino',
  },
  {
    img: require('../assets/sketches/Sketches-7.jpg'),
    title: "Manarola's train station",
  },
  {
    img: require('../assets/sketches/Sketches-8.jpg'),
    title: "Manarola's rocks",
  }
];