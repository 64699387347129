import React from 'react';
import { Link } from 'react-router-dom';

export default function Logo() {
  return (
  	<Link to="/" className="block" aria-label="Lattice">
      <h3 className="self-start h3 text-white font-headings">Ashkon Farhangi</h3>
  	</Link>
  );
}

