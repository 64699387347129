import React from 'react';

import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import GalleryArt from '../partials/GalleryArt';
import TikTok from '../partials/TikTok';
import Footer from '../partials/Footer';
import background from "../assets/backgrounds/codioful-formerly-gradienta-9X6tbXe_bw4-unsplash2.jpg";

function Home() {
  return (
    <div className="border-white px-4 sm:px-8 min-h-screen flex flex-col min-h-screen overflow-hidden bg-cover bg-no-repeat bg-top" style={{ backgroundImage: `url(${background})`, borderWidth: "16px" }}>
      <div className="hidden sm:block">
        <Header homePage={true} />
      </div>

      <main>
        <HeroHome />
        <div className="h-12" />
        <TikTok />
        <div className="h-16" />
        <GalleryArt />
      </main>

      <Footer />
    </div>
  );
}

export default Home;