import React from 'react';
import SectionHeading from '../utils/SectionHeading';

function TikTokLink(props) {
	return <a href="https://www.tiktok.com/@brandonau01/video/7213168848428682538?embed_source=121355058%2C121351166%2C121331973%2C120811592%2C120810756%3Bnull%3Bembed_blank&refer=embed&referer_url=localhost%3A3000%2F&referer_video_id=7213168848428682538" target="blank_">{props.label}</a>
}

export default function HeroHome() {
  return (
    <section className="max-w-6xl mx-auto" data-aos="fade-down" data-aos-delay="400">
	    <SectionHeading text="My claim to fame" subheading={<span>The subject of this <TikTokLink label="TikTok" /> in the red jacket is me. If you need a laugh today, click through to <TikTokLink label="TikTok.com" /> and read the comments below the video </span>} subheadingEmoji={<span role="img" aria-label="laugh">😂</span>} />
      	<div className="flex flex-col sm:flex-row overflow-hidden">
		  	<iframe
		    	src="https://www.tiktok.com/embed/7213168848428682538"
		  		allowfullscreen
		  		className="border-white border-t border-l-2 border-r-2 rounded-r-none rounded-l overflow-hidden mx-auto sm:mx-0"
		  		style={{ height: "735px", width: "320px" }}
		  		scrolling="no"
		  		title="TikTok" />
		  	<div className="hidden sm:block bg-cover bg-no-repeat bg-top border-white border-2 rounded-l-none rounded-r ml-2 grow flex-1 bg-white bg-opacity-75" />
		</div>
    </section>
  );
}
