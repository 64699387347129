import React from 'react';
import SectionHeading from '../utils/SectionHeading';

export default function HeroHome() {
  return (
    <section className="relative" >
      <div className="max-w-6xl mx-auto overflow-visible pt-20 sm:pt-40">
        <img className="border-8 border-white relative mb-8 mx-auto sm:ml-0" src={require('../assets/me.jpg')} alt="Profile" data-aos="fade-down" data-aos-delay="300" style={{height: "300px", width: "300px"}}/>
        <div data-aos="fade-down" data-aos-delay="300">
          <SectionHeading text="Hey, I'm Ashkon" h1={true} />
        </div>
        <div className="w-full">
          <h3 className="text-center sm:text-left h3 text-2xl sm:text-3xl font-standard text-transparent bg-clip-text bg-opacity-90 bg-white" data-aos="fade-down" data-aos-delay="350">PM by trade, CS by education, tinkerer, and chess player.</h3>
          <h4 className="text-center sm:text-left h4 text-xl sm:text-2xl font-standard text-transparent bg-clip-text bg-opacity-90 bg-white" data-aos="fade-down" data-aos-delay="400">This site exists to help you get to know me better.</h4>
        </div>
      </div>
    </section>
  );
}

