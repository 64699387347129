import React from 'react';
import SocialLink from '../utils/SocialLink';

export default function Socials(props) {
  return (
    <ul className="flex flex-wrap font-medium justify-center">
      <li className="ml-4">
        <SocialLink lime={props.lime} url="mailto:me@ashkonfarhangi.com" external={true} label={"Email"} svg="mail.svg" plausibleClass="plausible-event-name=EmailClicked" />
      </li>
      <li className="ml-4">
        <SocialLink lime={props.lime} url={"https://www.linkedin.com/in/ashkon-farhangi/"} external={true} label={"LinkedIn"} svg="linkedin.svg" plausibleClass="plausible-event-name=LinkedInClicked" />
      </li>
      <li className="ml-4">
        <SocialLink lime={props.lime} url={"https://github.com/ashkonf?tab=repositories"} external={true} label={"GitHub"} svg="github.svg" plausibleClass="plausible-event-name=GitHubClicked" />
      </li>
      <li className="ml-4">
        <SocialLink lime={props.lime} url={"https://www.chess.com/member/ashkonf"} external={true} label={"Chess.com"} svg="chess.svg" plausibleClass="plausible-event-name=ChessClicked" />
      </li>
    </ul>
  );
}
