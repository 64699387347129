import React from 'react';

const navigation = {
  main: [
    { name: 'Home', href: '/' },
    { name: 'Contact', href: 'mailto:me@ashkonfarhangi.com', external: true, plausibleClass: "plausible-event-name=EmailClicked" },
    { name: 'LinkedIn', href: 'https://www.linkedin.com/in/ashkon-farhangi/', external: true, plausibleClass: "plausible-event-name=LinkedInClicked" },
    { name: 'GitHub', href: 'https://github.com/ashkonf?tab=repositories', external: true, plausibleClass: "plausible-event-name=GitHubClicked" },
    { name: 'Chess.com', href: 'https://www.chess.com/member/ashkonf/', external: true, plausibleClass: "plausible-event-name=ChessClicked" },
  ]
}

export default function Footer() {
  return (
    <footer className="mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8" data-aos="fade-up" data-aos-delay="350">
      <nav className="flex flex-wrap justify-center" aria-label="Footer">
        {navigation.main.map((item) => (
          <div key={item.name} className="px-3 my-auto">
            <a href={item.href} target={item.external ? "blank_" : ""} className={"text-lime-600 text-lg font-medium hover:opacity-50 transition-all duration-200 " + item.plausibleClass}>
              {item.name}
            </a>
          </div>
        ))}
      </nav>
      <p className="text-md mt-4 text-center text-lime-600 opacity-75 font-medium">&copy; 2023 Ashkon Farhangi. <br className="block sm:hidden" />All rights reserved.</p>
    </footer>
  );
}

