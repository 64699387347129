import React from 'react';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import CtaButton from '../utils/CtaButton';
import background from "../assets/backgrounds/codioful-formerly-gradienta-9X6tbXe_bw4-unsplash.jpg";

export default function PageNotFound() {
  return (
    <div className="border-white px-4 sm:px-8 min-h-screen flex flex-col min-h-screen overflow-hidden bg-cover bg-no-repeat bg-top" style={{ backgroundImage: `url(${background})`, borderWidth: "16px" }}>
      <div className="hidden sm:block">
        <Header lime={true} />
      </div>

      <main>
        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <div className="max-w-3xl mx-auto text-center">
                <img src={require("../assets/svg/404.svg")} className="mx-auto w-40 h-40" alt="Not found" data-aos="fade-down" data-aos-delay="250" />
                <h1 className="h1 mb-4 font-headings text-5xl sm:text-6xl text-lime-600" data-aos="fade-down" data-aos-delay="300">Page not found!</h1>
                <div className="mt-12" data-aos="fade-down" data-aos-delay="350">
                  <CtaButton url="/" label="Back home" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}