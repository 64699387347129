import React from 'react';
import Logo from './Logo';
import Socials from './Socials';

export default function Header(props) {
  return (
    <header className="max-w-6xl mx-auto" data-aos="zoom-y-out" data-aos-delay="200">
      <div className="flex items-center justify-between h-16 md:h-20">
        <div className="flex-shrink-0 mr-4 pr-1 pb-1">
          <Logo />
        </div>
        <Socials lime={props.lime} />
      </div>
    </header>
  );
}
