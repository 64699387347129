import React from 'react';
import Header from '../partials/Header';
import Footer from '../partials/Footer';
import background from "../assets/backgrounds/codioful-formerly-gradienta-9X6tbXe_bw4-unsplash2.jpg";

export default function GetToKnowMe() {
  return (
    <div className="border-white px-4 sm:px-8 min-h-screen flex flex-col min-h-screen overflow-hidden bg-cover bg-no-repeat bg-top" style={{ backgroundImage: `url(${background})`, borderWidth: "16px" }}>
      <div className="hidden sm:block">
        <Header homePage={true} />
      </div>

      <main>
        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">
              <h1 className="h1 mb-4">asdfasd</h1>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
